<!-- price adjuster page -->
<template>
  <div class="loader-container" v-if="loadingData">
    <v-progress-circular
      color="primary"
      indeterminate
      model-value="20"
      :size="60"
      :width="6"
    ></v-progress-circular>
  </div>
  <scrollable v-else>
    <template #sticky>
      <v-card flat>
        <v-card-title class="d-flex justify-space-between">
          <span>{{ $t('Bulk Price Updater Detail') }}</span>
        </v-card-title>
        <v-divider />
      </v-card>
    </template>
    <template #scrollable-content="{ heightExceeded }">
      <v-container class="price_manager_wrapper price_managerdetails_wrapper">
        <div class="px-4 price_manager_wrapper">
          <div class="price_manager">
            <h3>{{ $t('Bulk Price Updater') }}</h3>
          </div>
        </div>
        <div class="priceSingle_wrapper">
          <v-row>
            <v-col col="6">
              <v-btn icon @click="redirectToDetailPage">
                <v-icon>mdi-arrow-left</v-icon>
              </v-btn>
              <span @click="redirectToDetailPage" class="back_btn btn_line">Back Home</span>
            </v-col>
            <v-col col="6">
              <div class="top-right-link text-right">
                <a href="" class="back_btn btn_line">Email Recepit</a>
              </div>
            </v-col>
          </v-row>
          <v-row></v-row>
          <v-row>
            <v-col col="12">
              <v-row>
                <v-col class="col-3 d-flex flex-wrap align-center">
                  <span class="heading_title mr-8"><strong>Title <span style="color: red;">*</span></strong></span>
                  <v-text-field v-model="title" label="" outlined class="input_element"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12">
              <v-row>
                <v-col class="col-3 d-flex flex-wrap align-center">
                  <span class="heading_title mr-8"><strong>Price <span style="color: red;">*</span></strong></span>
                  <v-text-field v-model="price" label="" outlined class="input_element price_col"></v-text-field>
                  <span class="mr-4"></span>
                  <span class="mr-4"><strong>%</strong></span>
                  <v-select v-model="priceStatus" item-value="value" item-text="label" :items="options" outlined
                    class="input_element price_status"></v-select>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12" class="tab_section">
              <v-tabs v-model="selectedTab" @change="handleTabChange">
                <v-tab v-for="(tab, index) in tabs" :key="index">
                  {{ tab.label }}
                </v-tab>
              </v-tabs>
              <!-- Brand Details Tab Content -->
              <v-row v-if="selectedTab === 0">
                <v-col col="12">
                  <v-row class="mt-0 select_option">
                    <v-col col="12">
                      <label>Brands:</label>
                      <v-autocomplete v-model="selectedBrands" :items="brands" :item-value="null" item-text="name"
                        outlined multiple></v-autocomplete>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0 select_option">
                    <v-col col="12">
                      <label>Categories:</label>
                      <v-autocomplete v-model="selectedCategories" :items="categories" :item-value="null"
                        item-text="name" outlined multiple></v-autocomplete>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <!-- Product Details Tab Content -->
              <v-row v-else-if="selectedTab === 1">
                <v-col>
                  <label class="product_label">Product Details:</label>
                  <div class="styled-text">
                    <div class="textarea-container">
                      <v-textarea v-model="ProductsSKU" @keydown.enter.prevent="handleEnter"
                        @keydown.space.prevent="handleSpace" label="Enter the product SKU here" outlined ref="textarea"
                        class="text_area_text"></v-textarea>
                      <div class="sku-container">
                        <span v-for="(sku, index) in styledText" :key="index" :class="{ 'sku-tag': sku }">
                          {{ sku }}
                          <i v-if="sku" class="remove-icon" @click="removeSku(index)">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30px" height="30px">
                              <path
                                d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z" />
                            </svg>
                          </i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <v-divider></v-divider>
                  <span class="info_box">
                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M7.57629 0.96875C3.88462 0.96875 0.894531 3.95884 0.894531 7.65051C0.894531 11.3422 3.88462 14.3323 7.57629 14.3323C11.268 14.3323 14.258 11.3422 14.258 7.65051C14.258 3.95884 11.268 0.96875 7.57629 0.96875ZM8.24447 10.9914H6.90811V6.98233H8.24447V10.9914ZM8.24447 5.64598H6.90811V4.30963H8.24447V5.64598Z"
                        fill="#1A5FA2" />
                    </svg>
                    To ensure a smooth process, kindly separate multiple SKUs while entering them by using commas (,).
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col col="12" class="effective_section">
              <v-row>
                <v-col class="col-12">
                  <v-radio-group v-model="effective">
                    <v-radio name="always" label="Always" value="always"></v-radio>
                    <v-radio name="from" label="From" value="from"></v-radio>
                  </v-radio-group>
                  <v-row class="pl-2">
                    <v-col class="col-2">
                      <v-text-field v-if="effective === 'from'" v-model="startDate" label="Select Date" type="date"
                        outlined></v-text-field>
                    </v-col>
                    <span v-if="effective === 'from'" class="ml-1 d-flex align-center">To:</span>
                    <v-col class="col-2">
                      <v-text-field v-if="effective === 'from'" v-model="endDate" label="Select Date" type="date"
                        outlined></v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="btn_row">
                <v-btn class="confirm_btn" @click="saveItem" :disabled="!title || !price || !priceStatus">Confirm</v-btn>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </template>

    <template #sticky-bottom="{ heightExceeded }">
      <v-divider />
      <v-footer v-if="heightExceeded" class="d-flex align-center pa-4 flex-gap-16 justify-end"></v-footer>
    </template>
  </scrollable>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import Scrollable from '@/components/layouts/Scrollable.vue'
import ButtonAction from '@/components/common/actions/ButtonAction.vue'
import { useApi } from '@/composable/useApi'

export default defineComponent({
  name: 'PriceDetail',
  components: { Scrollable, ButtonAction },
  data() {
    return {
      categories: [], // Array to hold the fetched categories
      selectedCategories: [], // Array to hold the selected category IDs
      brands: [],
      selectedBrands: [],
      id: '',
      title: '',
      price: '',
      priceStatus: '',
      effective: 'always',
      startDate: '',
      endDate: '',
      author: '',
      authorModifies: '',
      ProductsSKU: '',
      isChecked: false,
      loadingData : false,
      radios: '',
      data: [],
      editedItem: {
        title: '',
        price: '',
        priceStatus: '',
        brands: '',
        categories: '',
        effective: '',
        author: '',
        authorModifies: '',
        startDate: '',
        endDate: '',
        ProductsSKU: ''
      },
      options: [
        // Array of options
        { label: 'Decrease', value: 'Decrease' },
        { label: 'Increase', value: 'Increase' }
      ],

      selectedTab: 0,
      tabs: [{ label: 'Brand Details' }, { label: 'Product Details' }]
    }
  },

  watch: {
    isChecked(newVal) {
      const id = this.$route.params.id
      if (id) {
        return this.item.effective === 'Always'
      }
      if (newVal) {
        this.effective = 'Always'
      } else {
        this.effective = ''
      }
    }
  },

  created() {
    const id = this.$route.params.id
    if (id) {
      this.loadingData = true;
      const api = useApi();
      api.client
        .get(`/epic/bulk-price-detail/${id}`)
        .then((response) => {
          const getData = response.data.data
          this.item = getData;
          this.title = getData.title;
          this.price = getData.price;
          this.priceStatus = getData.priceStatus;
          this.effective = getData.effective;
          this.selectedBrands = getData.brands;
          this.selectedCategories = getData.categories;
          this.ProductsSKU = getData.ProductsSKU;
          this.startDate = getData.startDate;
          this.endDate = getData.endDate;
          if (this.selectedBrands.length > 0 || this.selectedCategories.length > 0) {
            this.selectedTab = 0
          } else {
            this.selectedTab = 1
            // this.ProductsSKU = ''
          }
        }).catch(error => {
          console.error('Error fetching data from API:', error);
        })
        .finally(() => {
          this.loadingData = false;
        });
    }
    const api = useApi(); // Call useApi within the lifecycle hook
    api.catalog
      .categories({
        sort: '-created_at',  // Static sort parameter
        perPage: -1           // Static perPage parameter
      })
      .then((value) => {
        this.categories = value.data.map(category => ({
          name: category.name,
          id: category.id
        }));
      })
      .finally(() => (this.grid.loading = false));
    api.catalog
      .brands({
        sort: '-created_at',  // Static sort parameter
        perPage: -1           // Static perPage parameter
      })
      .then((value) => {
        this.brands = value.data.map(brand => ({
          name: brand.name,
          id: brand.id
        }));
      })
      .finally(() => (this.grid.loading = false));

  },
  computed: {
    styledText() {
  if (Array.isArray(this.ProductsSKU)) {
    // It's an array, so map and trim only if the sku is available
    return this.ProductsSKU.map((sku) => sku ? sku.trim() : '');
  } else if (typeof this.ProductsSKU === 'string') {
    // It's a string, so split by comma and trim only if the sku is available
    return this.ProductsSKU.split(',').map((sku) => sku ? sku.trim() : '');
  }
}
  },

  mounted() {
    const id = this.$route.params.id
    if (id && this.item.effective === 'Always') {
      this.isChecked = true
    }

  },
  methods: {
    redirectToDetailPage() {
      this.$router.push('/panel/1/1/catalog/Price')
    },
    handleEnter() {
      const word = this.ProductsSKU.trim()
      if (word) {
        if (this.ProductsSKU.endsWith(',')) {
          this.ProductsSKU += ' '
        } else if (this.ProductsSKU.endsWith(' ') || this.ProductsSKU.endsWith(',')) {
          this.ProductsSKU += ''
        } else {
          this.ProductsSKU += ', '
        }
      }
    },

    handleSpace() {
      const word = this.ProductsSKU.trim()
      if (word) {
        if (this.ProductsSKU.endsWith(',')) {
          this.ProductsSKU += ' '
        } else if (this.ProductsSKU.endsWith(' ') || this.ProductsSKU.endsWith(',')) {
          this.ProductsSKU += ''
        } else {
          this.ProductsSKU += ', '
        }
      }
    },

    removeSku(index) {
      this.styledText.splice(index, 1)
      this.ProductsSKU = this.styledText.join(', ')
    },
    saveItem() {
      // Check if an item is being edited
      if (this.$route.params.id) {
        // Update the existing item
        this.updateItem()
      } else {
        // Add a new item
        this.addItem()
      }
    },

    handleTabChange(tab) {
      if (tab == 0) {
        this.ProductsSKU = ''
      } else {
        this.selectedCategories = []
        this.selectedBrands = []
      }
    },
    addItem() {

      const api = useApi();
      let productsSKUArray
      if (this.ProductsSKU) {
        productsSKUArray = this.ProductsSKU.split(',').map(sku => sku.trim());
      }
      const currentDate = new Date()
      const day = currentDate.getDate().toString().padStart(2, '0')
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
      const year = currentDate.getFullYear().toString().slice(2) // Get last two digits of the year
      const hours = currentDate.getHours() > 12 ? currentDate.getHours() - 12 : currentDate.getHours()
      const minutes = currentDate.getMinutes().toString().padStart(2, '0')
      const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM'
      const formattedTime = `${month}/${day}/${year} at ${hours}:${minutes} ${ampm}`
      //   return formattedTime;
      // Create a new item object
      const newItem = {
        // id: Date.now().toString(), // Generate a unique ID
        title: this.title,
        price: this.price,
        priceStatus: this.priceStatus,
        brands: this.selectedBrands,
        categories: this.selectedCategories, // Use the selected IDs here
        effective: this.effective,
        startDate: this.startDate,
        endDate: this.endDate,
        author: this.author,
        ProductsSKU: productsSKUArray ? productsSKUArray : [],
        authorModifies: formattedTime
        // Add other properties as needed
      }
      api.client
        .post(`/epic/bulk-price-insert`, newItem)
        .then((response) => {
          // Redirect to the detail page or perform any other action
          this.redirectToDetailPage();
        }).catch(error => {
          console.error('Error adding item:', error);
        });
    },
    updateItem() {
      const id = this.$route.params.id
      const api = useApi();
      if (typeof this.ProductsSKU === 'string') {
        const str = this.ProductsSKU;
        const arr = str.split(',').map(item => item.trim()); // Split and trim each element
        if (arr.length > 0) {
          this.ProductsSKU = arr
        }
      }
      const currentDate = new Date()
      const day = currentDate.getDate().toString().padStart(2, '0')
      const month = (currentDate.getMonth() + 1).toString().padStart(2, '0')
      const year = currentDate.getFullYear().toString().slice(2) // Get last two digits of the year
      const hours = currentDate.getHours() > 12 ? currentDate.getHours() - 12 : currentDate.getHours()
      const minutes = currentDate.getMinutes().toString().padStart(2, '0')
      const ampm = currentDate.getHours() >= 12 ? 'PM' : 'AM'
      const formattedTime = `${month}/${day}/${year} at ${hours}:${minutes} ${ampm}`
      const updateItem = {
        // id: Date.now().toString(), // Generate a unique ID
        title: this.title,
        price: this.price,
        priceStatus: this.priceStatus,
        brands: this.selectedBrands,
        categories: this.selectedCategories, // Use the selected IDs here
        effective: this.effective,
        startDate: this.startDate,
        endDate: this.endDate,
        author: this.author,
        ProductsSKU: (this.ProductsSKU && this.ProductsSKU.length === 1 && this.ProductsSKU[0] === "") ? [] : this.ProductsSKU,
        authorModifies: formattedTime
        // Add other properties as needed
      }
      api.client
        .post(`/epic/bulk-price-update/${id}`, updateItem)
        .then((response) => {
          // Redirect to the detail page or perform any other action
          this.redirectToDetailPage();
        }).catch(error => {
          console.error('Error adding item:', error);
        });
    }
  }
})
</script>

<style>
.styled-text {
  position: relative;

  /* Adjust as needed */
}

.sku-container {
  position: absolute;
  top: 0;
  left: 0;
  padding: 13px;
  /* Adjust as needed */
}

.sku-tag {
  background-color: #3371AD;
  border: 1px solid #ccc;
  border-radius: 7px;
  /* Add border radius */
  padding: 4px 8px;
  margin-right: 4px;
  margin-top: 4px;
  /* Add space on top */
  position: relative;
  /* Add this line */
  color: white;
}

.remove-icon {

  top: 0px !important;
  right: 0px !important;
  cursor: pointer;
  margin-left: 7px !important;
  font-size: 12px;
}

.remove-icon svg {
  max-width: 17px;
  fill: #ffffff;
  top: 4px;
  position: relative;
  right: -1px;

  border-radius: 50%;
  height: 20px;
  min-width: 20px;
  padding: 3px;
}

.text_area_text {
  padding-right: 12px !important;
  color: white !important;
}

.text_area_text textarea {
  opacity: 0 !important;
  color: white !important;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
}

</style>

<!-- price adjuster end -->
